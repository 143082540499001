import * as R from 'ramda'
import moment from 'moment'

const isNotNil = R.complement(R.isNil)
// const subnetRegex = /^((25[0-5]|2[0-4]\d|[01]?\d\d?)($|(?!\.$)\.)){4}\/\d{2}$/;
const vifAddressRegex = /^(25[0-5]|2[0-4]\d|[01]?\d\d?)(\.(25[0-5]|2[0-4]\d|[01]?\d\d?)){3}\/30$/
const IPRegex = /^(22[0-3]|2[0-1]\d|[01]?\d?\d)(\.(25[0-5]|2[0-4]\d|[01]?\d\d?)){3}$/
const subnetRegex = /^(22[0-3]|2[01]\d|[01]?\d?\d)(\.(25[0-5]|2[0-4]\d|[01]?\d\d?)){3}\/(([8-9])|([1-2]\d)|(30))$/
const passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9])\S{6,20}$/
const excludeIPRegex = [/^0{1,3}\./, /^127\./, /^169\.254/]
export const internalIPRegex = /^((0?10(\.(25[0-5]|2[0-4]\d|[01]?\d\d?)){3})|((192\.168)|(172\.0?(1[6-9]|2\d|3[0-1])))(\.(25[0-5]|2[0-4]\d|[01]?\d\d?)){2})$/
const internalIPWithSubnetRegex = /^(((0?10(\.(25[0-5]|2[0-4]\d|[01]?\d\d?)){3})|((192\.168)|(172\.0?(1[6-9]|2\d|3[0-1])))(\.(25[0-5]|2[0-4]\d|[01]?\d\d?)){2})\/(([8-9])|([1-2]\d)|(30)))$/
const subnetMaskRegex = /^((128|192)|2(24|4[08]|5[245]))(\.(0|(128|192)|2((24)|(4[08])|(5[245])))){3}$/

export const assert = R.curry((fn, msg) => ([val, obj]) => {
  // 空字段不需要验证
  if (R.isNil(val) || val === '') {
    return [val, obj]
  }
  if (fn(val)) {
    return [val, obj]
  } else {
    throw new Error(typeof msg === 'function' ? msg(val) : msg)
  }
})

export const assertObj = R.curry((fn, msg) => ([val, obj]) => {
  console.log('val', val)
  if (fn(val, obj)) {
    return [val, obj]
  } else {
    throw new Error(typeof msg === 'function' ? msg(val) : msg)
  }
})

export const required = (msg = 'required') => ([val, obj]) => {
  if (isNotNil(val) && val !== '' && !R.isEmpty(val)) {
    return [val, obj]
  } else {
    throw new Error(typeof msg === 'function' ? msg(val) : msg)
  }
}

export const requiredArr = (msg = 'required') => ([val, obj]) => {
  if (Object.keys(val).length !== 0) {
    return [val, obj]
  } else {
    throw new Error(typeof msg === 'function' ? msg(val) : msg)
  }
}

export const isSubnetMask = (msg = 'required') => ([val, obj]) => {
  if (subnetMaskRegex.test(val)) {
    return [val, obj]
  } else {
    throw new Error(typeof msg === 'function' ? msg(val) : msg)
  }
}

export const requiredIf = R.curry((fn, msg) => assertObj((val, obj) => fn(obj) && isNotNil(val), msg))

export const test = R.curry((reg, msg) => assert(R.test(reg), msg))

export const isEmail = (msg = 'isEmail') => test(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/, msg)
export const isIP = (msg = 'isIP') => assert((val) => {
  return R.test(IPRegex, val) && (!R.anyPass(R.map(R.test, excludeIPRegex))(val))
}, msg)

export const isInternalIP = (msg = 'isInternalIP') => test(internalIPRegex, msg)
export const isInternalIPWithSubnet = (msg = 'isInternalIP') => test(internalIPWithSubnetRegex, msg)
export const isGlobalTelephone = (msg = 'isGlobalTelephone') => test(/^\d[0-9-]+\d+$/, msg)
export const isFullTelephone = (msg = 'isGlobalTelephone') => test(/^\+\d+\s[0-9-]+\d+?$/, msg)
export const isTelephone = (msg = '电话号码格式错误') => test(/^((1\d{10})|(([0-9]{3,4}-)?[0-9]{7,8}))$/, msg)
export const isInteger = (msg = '请输入数字') => test(/^\d+$/, msg)
export const isSubnet = (msg = 'validSubnet') => assert((val) => {
  console.log(val)
  console.log(R.test(subnetRegex, val))
  console.log(R.anyPass(R.map(R.test, excludeIPRegex))(val))
  return R.test(subnetRegex, val) && (!R.anyPass(R.map(R.test, excludeIPRegex))(val))
}, msg)
export const validPassword = (msg = 'validPassword') => test(passwordRegex, msg)
export const isVifAddress = (msg = 'isSubnet') => assert((val) => {
  if (!R.test(vifAddressRegex, val)) {
    return false
  }
  // 地址的最后一位 “跟3与” 或者 “反转之后跟3与” 的结果都不能为0
  const x = parseInt(val.match(/\.(\d+)\/30$/)[1])
  // console.log("x = ", x, x & 3, (~x) & 3, ((x & 3) != 0) && (((~x)  & 3) != 0));
  if (((x & 3) !== 0) && (((~x) & 3) !== 0)) {
    return true
  }
  return false
}, msg)
export const hasNoSpace = (msg = 'hasNoSpace') => test(/^[\S]*$/, msg)
// 只允许数字、英文大小写、括号()、下划线_、短横线-、小数点.
export const hasNoSpecialChar = (msg = 'hasNoSpecialChar') => test(/^[\w()_.-]+$/, msg)
// 名称 允许中文
export const hasNoSpecialCharButChinese = (msg = 'hasNoSpecialCharButChinese') => test(/^[\u4e00-\u9fa5\w()_.-]+$/, msg)
export const letterOrNumber = (msg = 'letterOrNumber') => test(/^[\w\d]+$/, msg)

export const afterCurrentTime = assert((val) => new Date(val).valueOf() > Date.now().valueOf())
export const afterCurrentHour = (msg = 'afterCurrentHour') => assert((val) => new Date(val).valueOf() >= moment().startOf('hour').valueOf(), msg)

export const maxLength = (len, msg = `maxLength__${len}`) => assert((val) => val.length <= len, msg)
export const minLength = (len, msg = `minLength__${len}`) => assert((val) => val.length >= len, msg)

export const between = (min, max, msg = `between__${min}__${max}`) => assert((val) => val >= min && val <= max, msg)
export const bgpAsnValid = () =>
  assert((val) => ((val >= 1 && val <= 64511) || (val >= 65100 && val <= 65299)) && val !== 45090, 'bgpAsnValid')

export const min = (min, msg = `min__${min}`) => assert((val) => val >= min, msg)
export const max = (max, msg = `max__${max}`) => assert((val) => val <= max, msg)
export const isAWSAccount = (msg = 'isAWSAccount') => test(/^\d{12}$/, msg)
export const validVlanRangeSyntax = (msg = 'validVlanRangeSyntax') => test(/^((\d+-\d+)($|(?!,$),))+$/, msg)
// 名称只允许中文
export const checkChinese = (msg = '请输入中文') => test(/^[\u4e00-\u9fa5]+$/, msg)
export const checkFloat = (msg = 'checkFloat') => test(/^-?\d+(\.\d{0,2})?$/, msg)

export const validInitialReg = /^[(\u4e00-\u9fa5)|(a-zA-Z)][\u4e00-\u9fa5_a-zA-Z0-9-]*$/
export const validInitial = (msg = 'validInitial') => test(validInitialReg, msg)

// 首字母英文
export const letterInitial = (msg = 'letterInitial') => test(/^[a-zA-Z]/, msg)

export const notUrlReg = /^(?!.*?(http|https):\/\/).*$/
export const notUrl = (msg = 'notUrl') => test(notUrlReg, msg)

export const isEqual = (val1, val2, name1, name2, msg = `isDeferent__${name1}__${name2}`) => ([val, obj]) => {
  if (val1 !== val2) {
    return [val, obj]
  } else {
    throw new Error(typeof msg === 'function' ? msg(val) : msg)
  }
}

export const errorMessage = (error) => {
  let message = ''
  if (error.fieldErrors && Array.isArray(error.fieldErrors)) {
    message = error.fieldErrors[0].message
  } else if (error.title) {
    message = error.title
  }
  return message
}
