/* eslint-disable */
import * as R from 'ramda'

function validate (validators, data, obj) {
  const isFn = typeof (validators) === 'function'
  validators = isFn ? validators.bind(this)(data, obj) : validators
  let p = Promise.resolve([data, obj])
  validators.forEach((v) => p = p.then(v))
  return p
}

export default ({ rules = {}, namespace = 'validation', field }) => {
  return {
    data () {
      return {
        [namespace]: R.mapObjIndexed(() => ({
          validating: false,
          valid: true,
          hasError: false,
          message: null
        }))(rules)
      }
    },
    methods: {
      $resetValidate () {
        for (const k in rules) {
          this.$resetValidateField(k)
        }
      },
      $validateField (k) {
        this.$validateFieldWithError(k).catch(() => { })
      },
      $validateFieldWithError (k) {
        const obj = this[field]
        const p = validate.bind(this)(rules[k], obj[k], obj).then(() => {
          this.$resetValidateField(k)
        }).catch((e) => {
          const msgs = e.message.split('__')
          const [message, meta] = R.juxt([R.head, R.tail])(msgs)
          this[namespace][k] = {
            hasError: true,
            valid: false,
            // key__meta1__meta2
            message: message
          }
          throw e
        })
        return p
      },
      $validate () {
        const promises = []
        for (const k in rules) {
          const p = this.$validateFieldWithError(k)
          promises.push(p)
        }
        return Promise
          .all(promises)
          .catch((e) => {
            throw e
          })
      },
      $resetValidateField (name) {
        this[namespace][name] = {
          hasError: false,
          valid: true,
          message: null
        }
      }
    }
  }
}
